<template>
  <b-card
    border-variant="primary"
    
  >
    <b-row>
       <b-col cols="12">
        <div style="float:left" class="flight-info text-align-left">
          {{flightInfo.transportNumber}}
          <br>
          <small>{{flightInfo.operator}}</small>
        
        </div>
        <div  style="float:right">
          <b-button  variant="outline-primary" v-if="canEdit" @click="openEditFlightModal" size="sm">Modifier</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="text-align-left">
      <b-col cols="5">
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="airport-code">{{flightInfo.from}}</div>
            <div class="airport-city">{{airportsdetails.departure.name}}</div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
          
            <div class="flight-time">{{ 
              this.$luxon(flightInfo.departureDateTime, {
                input: { format: "yyyy-MM-dd HH:mm", zone: "local"},
                output: "HH:mm a",
              })
              }}
            </div>
            <div class="flight-date">{{ 
              this.$luxon(flightInfo.departureDateTime, {
                input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
                output: "dd-MM-yy",
              })
              }}</div>

          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <b-row>
          <b-col cols="12">
            <div class="flight-info">{{flightInfo.transport_number}}</div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <div class="airport-code">{{flightInfo.to}}</div>
            <div class="airport-city">{{airportsdetails.arrival.name}}</div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
           
            <div class="flight-time">{{
              this.$luxon(flightInfo.arrivalDateTime, {
                input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
                output: "HH:mm a",
              })
              }}</div>
            <div class="flight-date">
              {{
                this.$luxon(flightInfo.arrivalDateTime, {
                  input: { format: "yyyy-MM-dd HH:mm", zone: "local" },
                  output: "dd-MM-yy",
                })
              }}
            </div>
             </b-col>
        </b-row>        
      </b-col>
    </b-row>
    


  </b-card>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardBody, BCardText, BButton,BButtonGroup,BCollapse,VBToggle, BIconFileEarmarkEasel } from 'bootstrap-vue'
import {useTravelInformationApi} from "@/modules/group/composables/use-travel-information-api";
import {computed,onMounted, reactive, watch, ref} from "@vue/composition-api";
import {useApplicationContext} from "@/shared/composables/use-application-context";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BCollapse,
    BButtonGroup
  },
  props: {
    flightInfo: Object,
    canEdit: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit}) {
    const {getAirlineInformationMatchingOperator, getAirportsMatchingIataCode} = useTravelInformationApi();
    let airportsdetails = reactive({
      departure: {},
      arrival: {}
    });
    const {forceUpdate} = useApplicationContext();

    const getAirportDetail = async (airport, direction) => {
      try {
        const airports = await getAirportsMatchingIataCode(airport);
        Object.assign(airportsdetails[direction], airports[0]);
        forceUpdate();
      } catch (error) {
        console.error(error)
      }
    };



    const openEditFlightModal = () => {
      emit("openEditFlightModal")
    }

    onMounted(() => {
      getAirportDetail(props.flightInfo.from, 'departure');
      getAirportDetail(props.flightInfo.to, 'arrival');
    });

    return {
      openEditFlightModal,
      getAirportsMatchingIataCode,
      airportsdetails
    }
  },
  data() {
    return {
      hasOneOption: true,
      testDate: Date.now(),
      currentGroup: {},
    }
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
    showBasicInfoModal() {
      
    },

    createCurrentGroup(currentGroup) {

    }
  }
}
</script>

<style>
  .text-align-left {
    text-align: left;
  }

  .text-align-right {
    text-align: right;
  }

  .text-white {
    color:white;
  }

  .flight-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
  }

  .airport-code {
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
  }

  .flight-time {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
  }

  .airport-city, .flight-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    margin-top: 2px
  }

  .small-top-padding {
    padding: 0;
    padding-top:10px;
  }

</style>
